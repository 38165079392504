// import * as a from "./setup.js";

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
// import { OBJLoader, objLoader } from 'three/examples/jsm/loaders/OBJLoader'

import baseSkin from "../../static/skins/steve.png";
import visibleSvg from "../icons/visibility.svg"
import visibleOffSvg from "../icons/visibility_off.svg"
import shuffle from "../icons/dice5.svg"
import centerFocus from "../icons/center_focus.svg"
// import { Group } from "three";

const selectedTrimsDisp = document.getElementById("selected-trims");

const helmetUrl = new URL('../objects/helmet_all.obj', import.meta.url);
const chestUrl = new URL('../objects/chest_all.obj', import.meta.url);
const legUrl = new URL('../objects/legs_all.obj', import.meta.url);
const bootUrl = new URL('../objects/boot_all.obj', import.meta.url);
const playerUrl = new URL('../objects/player.obj', import.meta.url);
const imageEditor = new ImageEditor();

const gear_settings = {

    head: [
        {
            icon: "items/armor/iron_helmet.png",
            texture: "base/armors/iron_layer_1.png",
            objUrl: helmetUrl,
            obj: "",
            onEquip: () => { }
        }
    ]
}

// let g = new Group();
// let LastArmorForceUpdate = Date.now();

let trimSetsHandler = "";
const deleteBtn = document.getElementById("delete-button");

let clicked = "";
let skinInput = document.getElementById("skin-input");

function randFromArr(arr) {
    const rand = Math.floor(Math.random() * (arr.length - 0.001));
    return arr[rand];
}

class ArmorPiece {
    constructor(objectDatas, sc, piece_type) {

        this.WorldObjectsDataList = objectDatas;

        this.visible = true;
        this.inputs = [];
        this.piece_type = piece_type;
        this.textureIndex = 0;
        this.hasCustomTexture = false;
        let i = 0;
        for (const objectData of this.WorldObjectsDataList) {
            this.loadObj(objectData, i == this.WorldObjectsDataList.length - 1);
            i++;
        }

        this.oldArmorType = "";
    }

    getSubUrl() {
        return `${this.armorType.substring(0, 2)}_${this.trimModel.substring(0, 2)}_${this.trimPalette.substring(0, 2)}`;
    }

    setTextureIndex(index) {
        this.textureIndex = index;
        return this;
    }

    addInpSrc(src) {
        this.inputs.push(src);
    }

    setVisible(visibility) {
        this.visible = visibility;

        for (const objData of this.WorldObjectsDataList) {

            objData.object.visible = visibility;
        }
    }

    updateTexture() {

        if (trimSetsHandler != "" && this.piece_type != "player")
            trimSetsHandler.updateCurrTrim(this.piece_type, this.armorType, this.trimModel, this.trimPalette);

        if (!this.hasCustomTexture) {

            let armorPath = "./base/armors/" + this.armorType + "_layer_" + this.textureIndex + ".png"
            let trimPath = "./base/trims/" + this.trimModel + (this.textureIndex == 2 ? "_leggings" : "") + ".png"
            let palettePath = "./base/palettes/" + this.trimPalette + (this.armorType == this.trimPalette ? "_darker" : "") + ".png";

            // console.log(trimPath);

            imageEditor.combineImages(armorPath, trimPath, palettePath)
                .then(dataURL => {

                    let texture = textureLoader.load(dataURL,

                        () => {
                            texture.minFilter = THREE.NearestFilter;
                            texture.magFilter = THREE.NearestFilter;

                            for (const obj_data of this.WorldObjectsDataList)
                                this.updateTexturePerObj(obj_data, texture, dataURL);

                            this.oldArmorType = this.armorType;
                        }
                    );
                })
                .catch(error => {
                    console.error(error);
                });
        }
        else {

            let texture = textureLoader.load(this.customTexture);
            texture.minFilter = THREE.NearestFilter;
            texture.magFilter = THREE.NearestFilter;

            for (const obj_data of this.WorldObjectsDataList)
                this.updateTexturePerObj(obj_data, texture, this.customTexture);
        }

        for (const src of this.inputs) {

            src.armor.topImg.src = `items/armor/${this.armorType.replace("gold", "golden")}_${this.piece_type}.png`;
            src.armor.topText.innerText = `${this.armorType.replace("gold", "golden")}`;
            src.trim.topImg.src = `items/trims/${this.trimModel}_armor_trim_smithing_template.png`;
            src.trim.topText.innerText = `${this.trimModel}`;
            src.palette.topImg.src = `items/coloring/${this.trimPalette}.png`;
            src.palette.topText.innerText = `${this.trimPalette}`;
            src.input.value = `/give @p minecraft:${this.armorType.replace("gold", "golden")}_${this.piece_type}{Trim:{material:"minecraft:${this.trimPalette}",pattern:"minecraft:${this.trimModel}"}}`;
        }
        updateTrimLink();
        updateSelectedTrimsDisp();
        // this.oldArmorType = this.armorType;
    }

    updateTexturePerObj(objectData, texture, dataURL) {

        if (objectData.obj == undefined)
            return;

        const obj = objectData.object;
        let box = obj;

        if (!this.hasCustomTexture) {

            if (this.armorType != this.oldArmorType || this.armorType == "chainmail" || this.oldArmorType == "chainmail") {

                objectData.object.geometry = objectData.geometry.clone();
                // console.log("reset model", objectData.object);
            }

            box.material = new THREE.MeshBasicMaterial({
                map: texture,
                // transparent: true,
                side: THREE.DoubleSide
            });

            // console.log(texture.source.data.src);
            // let dataURL = texture.source.data.src;
            updModel(box, dataURL)
        }
        else {

            box.material = new THREE.MeshBasicMaterial({
                map: texture,
                // transparent: true,
                side: THREE.DoubleSide
            });
        }
    }

    getCenterPoint() {
        const center = new (0, THREE.Vector3)(0, 0, 0);
        for (const obj of this.WorldObjectsDataList) center.add(obj.pos);
        center.y /= this.WorldObjectsDataList.length;
        return center;
    }

    // getTexture(index) {
    //     if (index != 0) return `${this.armorType}_layer_${index}&${this.trimModel}&${this.trimPalette}${this.armorType == this.trimPalette ? "_darker" : ""}`;
    // }

    setCustomTexture(customTexture) {
        // console.log("texture", customTexture);
        this.hasCustomTexture = true;
        this.customTexture = customTexture;
        return this;
    }

    loadObj(objectData, isLastObj) {

        const objLoader = new OBJLoader();

        objLoader.load(objectData.url.href, (obj) => {

            // console.log("find face test", obj, this.piece_type, objectData.url.href);

            const objList = !Object.hasOwnProperty.call(obj, "children") || obj.children.length == 0 ? [obj] : obj.children;

            this.WorldObjectsDataList = [];

            for (let r = objList.length - 1; r >= 0; r--) {
                const currObj = objList[r];

                currObj.position.y += 1;

                if (Object.hasOwnProperty.call(objectData, "pos"))
                    currObj.position.set(objectData.pos.x, objectData.pos.y, objectData.pos.z);

                if (Object.hasOwnProperty.call(objectData, "rot")) {
                    currObj.rotation.x = objectData.rot.x;
                    currObj.rotation.y = objectData.rot.y;
                    currObj.rotation.z = objectData.rot.z;
                }

                if (Object.hasOwnProperty.call(objectData, "scale")) {
                    if (typeof objectData.scale == "number") {
                        currObj.scale.x = objectData.scale;
                        currObj.scale.y = objectData.scale;
                        currObj.scale.z = objectData.scale;
                    } else {
                        currObj.scale.x = objectData.scale.x;
                        currObj.scale.y = objectData.scale.y;
                        currObj.scale.z = objectData.scale.z;
                    }
                }

                if (Object.hasOwnProperty.call(objectData, "mirror"))
                    currObj.scale.z = -1 * currObj.scale.z;

                const newObjData = JSON.parse(JSON.stringify(objectData));
                newObjData.obj = currObj;
                newObjData.object = currObj;
                newObjData.geometry = currObj.geometry.clone();

                this.WorldObjectsDataList.push(newObjData);
                scene.add(currObj);
            }
            this.updateTexture();
        });
    }
}

skinInput.addEventListener("change", (e) => {

    // console.log(skinInput.value);
    const reader = new FileReader();
    reader.addEventListener("load", () => {

        // console.log("result", reader.result, typeof reader.result);
        addCookie("skin1", reader.result.replace(";", "&semicolon&"));
        playerObj.setCustomTexture(reader.result);
        // playerObj.setCustomTexture(skinInput.value);
        playerObj.updateTexture();
    });
    reader.readAsDataURL(skinInput.files[0]);
});

const playerObj = new ArmorPiece([
    {
        url: playerUrl,
    }
], 1, "player").setCustomTexture(getCookie("skin1") != "" ? getCookie("skin1").replace("&semicolon&", ";") : baseSkin);

function loadSkinsElem() {

    const skinsHolder = document.getElementById("skins-select");

    // const holder = createElem("div", "", parent, "", ["dropdown"]);
    const selecetedHolder = createElem("div", "", skinsHolder, "selected-skin", []);
    const optionsHolder = createElem("div", "", skinsHolder, "", []);

    const currSkin = createElem("div", premadeSkins[0], selecetedHolder, "", []);

    currSkin.addEventListener("click", () => {

        if (clicked != "" && clicked != skinsHolder)
            clicked.classList.remove("clicked");

        skinsHolder.classList.toggle("clicked");
        clicked = skinsHolder;
    });

    for (const skin of premadeSkins) {

        const btn = createElem("div", skin, optionsHolder, "", []);

        btn.addEventListener("mouseover", e => {

            // console.log(e.srcElement.innerText);
            currSkin.innerText = e.srcElement.innerText;
            playerObj.setCustomTexture(`skins/${skin}.png`);
            playerObj.updateTexture();
            addCookie("skin1", `skins/${skin}.png`);
        });

        btn.addEventListener("click", () => {

            // topImgElem.src = imgSrc;
            skinsHolder.classList.remove("clicked");
            // console.log("close");
        });
    }
}
loadSkinsElem();

const helmetObj = new ArmorPiece([
    {
        url: helmetUrl,
    }
], 1, "helmet").setTextureIndex(1);

const chestplateObj = new ArmorPiece([
    {
        url: chestUrl,
    }
], 1, "chestplate").setTextureIndex(1);

const leggingsObj = new ArmorPiece([
    {
        url: legUrl,
    }
], 1, "leggings").setTextureIndex(2);

const bootsObj = new ArmorPiece([
    {
        url: bootUrl,
    }
], 1, "boots").setTextureIndex(1);
// history.replaceState(null, null, new URL(window.location.origin).href);

// -----------------------------------------------------------------------------------------------------------------------
const scene = new THREE.Scene();
const light = new THREE.AmbientLight("0x404040"); // soft white light
scene.add(light);
const renderer = new THREE.WebGLRenderer({
    preserveDrawingBuffer: true
});
const canvasHolder = document.getElementById("canvas-holder");
renderer.setSize(canvasHolder.offsetWidth, canvasHolder.offsetHeight * 0.77);
canvasHolder.appendChild(renderer.domElement);
// -----------------------------------------------------------------------------------------------------------------------
// Create a camera
const camera = new THREE.PerspectiveCamera(75, canvasHolder.offsetWidth / (canvasHolder.offsetHeight * 0.77), 0.1, 1000);
camera.position.z = 5;
camera.fov = 70;
camera.updateProjectionMatrix();

const orbit = new OrbitControls(camera, renderer.domElement);
const axesHelper = new THREE.AxesHelper(5);
// scene.add(axesHelper);
// -----------------------------------------------------------------------------------------------------------------------
const textureLoader = new THREE.TextureLoader();
// -----------------------------------------------------------------------------------------------------------------------
const valuesHolder = document.getElementById("armor-values");

newSet("full", [
    helmetObj,
    chestplateObj,
    leggingsObj,
    bootsObj
], valuesHolder);

// createElem("div", "", valuesHolder, "", ["line"]);
const armorsBox = createElem("div", "", valuesHolder, "", ["pop-out-box"]);

const helmetSettings = newSet("helmet", [helmetObj], armorsBox);

// helmetSettings
addExtraArmor(helmetSettings.children[0], (e) => {

    helmetObj.armorType = e.srcElement.value;
    helmetObj.updateTexture();

}, `items/armor/turtle_helmet.png`, "turtle");

newSet("chestplate", [chestplateObj], armorsBox);
newSet("leggings", [leggingsObj], armorsBox);
newSet("boots", [bootsObj], armorsBox);

const cameraMover = new CameraMover(camera, "still", 0.001, 5);
// const backColorPicker = document.getElementById("back-color-picker");
const backColorPicker = createElem("input", "", "", "", []);
backColorPicker.type = "color";
backColorPicker.value = "#96C8FF";

backColorPicker.value = getCookie("background_color") != "" ? getCookie("background_color") : backColorPicker.value;

backColorPicker.addEventListener("input", () => {

    addCookie("background_color", backColorPicker.value)
})

render();

function addExtraArmor(dropdown, func, imgPath, itemName) {

    const imgElem = createElem("img", "", dropdown.children[1], "", []);

    imgElem.value = itemName;
    imgElem.alt = itemName;

    imgElem.src = imgPath;
    imgElem.addEventListener("mouseover", func);
    imgElem.addEventListener("click", (e) => {

        dropdown.children[0].src = imgPath;
        dropdown.classList.remove("clicked");
    });

}

function render() {

    cameraMover.update();
    requestAnimationFrame(render);
    renderer.render(scene, camera);
    renderer.setClearColor(backColorPicker.value)
}

// ---------------------------------------------------------------------------------

const randomHolder = createElem("div", "", document.getElementById("armor-values"), "random-holder", []);

addRandomBtn(e => {

    helmetObj.armorType = randFromArr(armor_types);
    chestplateObj.armorType = randFromArr(armor_types);
    leggingsObj.armorType = randFromArr(armor_types);
    bootsObj.armorType = randFromArr(armor_types);

    updateArmorObjects();
    setDieBtnDispNumb(e, Math.floor(Math.random() * 5.9));
});

addRandomBtn(e => {

    helmetObj.trimModel = randFromArr(trimModels);
    chestplateObj.trimModel = randFromArr(trimModels);
    leggingsObj.trimModel = randFromArr(trimModels);
    bootsObj.trimModel = randFromArr(trimModels);

    updateArmorObjects();
    setDieBtnDispNumb(e, Math.floor(Math.random() * 5.9));
});

addRandomBtn(e => {

    helmetObj.trimPalette = randFromArr(trimPalettes);
    chestplateObj.trimPalette = randFromArr(trimPalettes);
    leggingsObj.trimPalette = randFromArr(trimPalettes);
    bootsObj.trimPalette = randFromArr(trimPalettes);

    updateArmorObjects();
    setDieBtnDispNumb(e, Math.floor(Math.random() * 5.9));
});

function updateArmorObjects() {
    helmetObj.updateTexture();
    chestplateObj.updateTexture();
    leggingsObj.updateTexture();
    bootsObj.updateTexture();
}

function addRandomBtn(onClick) {

    // const randomBtn = createElem("img", "rand", randomHolder, "", ["random-btn"]);
    // randomBtn.src = shuffle;

    const randomBtn = createElem("div", "", randomHolder, "", ["die"]);

    for (let i = 0; i < 9; i++) {
        createElem("div", "", randomBtn, "", ["die-blob"]);
    }

    setDieBtnDispNumb(randomBtn, 4);
    randomBtn.addEventListener("click", () => { onClick(randomBtn) });
}

function setDieBtnDispNumb(btn, number) {

    if (btn.children.length == 0)
        return;

    for (const child of btn.children) {

        child.classList.remove("die-blob");
    }

    for (const blobIndex of dieLayout[number]) {


        // if (btn.children[blobIndex] == undefined)
        // console.log(btn, number);

        btn.children[blobIndex].classList.add("die-blob");
    }
    // child.classList.remove("die-blob");
}

function newSet(armorName, worldObjects, parent) {

    const holder = createElem("div", "", parent, "", ["settings-set", worldObjects.length > 1 ? "pop-out-box" : "a"]);
    // const holder = createElem("div", "", valuesHolder, "", ["settings-set", worldObjects.length > 1 ? "pop-out-box" : "a"]);

    // createElem("h2", armorName, holder, "", []);
    const topImg1 = createDropdownFromArr(armor_types, armorName, "armor", holder, (e) => {
        for (const obj of worldObjects) {
            obj.armorType = e.srcElement.value;
            obj.updateTexture();
        }
    }, worldObjects.length);

    const topImg2 = createDropdownFromArr(trimModels, armorName, "trims", holder, (e) => {
        for (const obj of worldObjects) {
            obj.trimModel = e.srcElement.value;
            obj.updateTexture();
        }
    }, worldObjects.length);

    const topImg3 = createDropdownFromArr(trimPalettes, armorName, "coloring", holder, (e) => {
        for (const obj of worldObjects) {
            obj.trimPalette = e.srcElement.value;
            obj.updateTexture();
        }
    }, worldObjects.length);

    const hideElem = createElem("img", "", holder, "", []);
    hideElem.src = visibleSvg;
    hideElem.alt = "hide armor piece";
    hideElem.addEventListener("click", () => {

        for (const obj of worldObjects) {

            obj.setVisible(!obj.visible);
            // hideElem.src = obj.visible ? visibleSvg : visibleOffSvg;
        }
    });

    const focusedImg = createElem("img", "", holder, "", []);
    focusedImg.alt = "focus in on armor piece";
    focusedImg.src = centerFocus;
    focusedImg.addEventListener("click", () => {

        if (worldObjects.length > 1) {
            cameraMover.focusPoint = { x: 0, y: 0, z: 0 };
            camera.position.y = cameraMover.focusPoint.y;
            cameraMover.distance = 4;

        } else {
            cameraMover.focusPoint = worldObjects[0].getCenterPoint();
            camera.position.y = cameraMover.focusPoint.y;
            cameraMover.distance = 3;
        }

    });

    if (worldObjects.length == 1) {

        // let input = createElem("input", "", holder, "", ["hidden"]);
        let input = createElem("input", "", holder, "", []);

        input.addEventListener("click", (e) => {
            // Get the text field
            var copyText = e.srcElement;
            // Select the text field
            copyText.select();
            copyText.setSelectionRange(0, 99999); // For mobile devices
            // Copy the text inside the text field
            navigator.clipboard.writeText(copyText.value);
        });

        worldObjects[0].addInpSrc({
            armor: topImg1,
            trim: topImg2,
            palette: topImg3,
            input: input
        });
    }

    return holder;
}

function createDropdownFromArr(arr, armorName, imgPath, parent, func, worldObjectsLength) {

    const holder = createElem("div", "", parent, "", ["dropdown"]);
    const selecetedHolder = createElem("div", "", holder, "", []);
    const optionsHolder = createElem("div", "", holder, "", []);

    selecetedHolder.addEventListener("click", () => {

        if (clicked != "" && clicked != holder)
            clicked.classList.remove("clicked");

        holder.classList.toggle("clicked");
        clicked = holder;
    });

    const topImgElem = createElem("img", "", selecetedHolder, "", []);
    // const nameElem = createElem("p", arr[0], selecetedHolder, "", [worldObjectsLength > 1 ? "hidden" : "normal"]);
    const nameElem = createElem("p", arr[0], selecetedHolder, "", ["hidden"]);

    topImgElem.value = arr[0];
    topImgElem.alt = arr[0];

    let elem = imgPath == "armor" ? arr[0].replace("gold", "golden") : arr[0];
    if (imgPath == "armor")
        topImgElem.src = `items/${imgPath}/${elem}_${armorName}.png`;

    else if (imgPath == "trims")
        topImgElem.src = `items/${imgPath}/${elem}_armor_trim_smithing_template.png`;

    else
        topImgElem.src = `items/${imgPath}/${elem}.png`;

    for (let elem of arr) {
        const imgElem = createElem("img", "", optionsHolder, "", []);

        imgElem.value = elem;
        imgElem.alt = elem;
        elem = imgPath == "armor" ? elem.replace("gold", "golden") : elem;

        // elem = elem.replace("gold", "golden");
        let imgSrc = "";

        if (imgPath == "armor")
            imgSrc = `items/${imgPath}/${elem}_${armorName}.png`;
        else if (imgPath == "trims")
            imgSrc = `items/${imgPath}/${elem}_armor_trim_smithing_template.png`;

        else
            imgSrc = `items/${imgPath}/${elem}.png`;

        imgElem.src = imgSrc;
        imgElem.addEventListener("mouseover", func);
        imgElem.addEventListener("click", (e) => {
            topImgElem.src = imgSrc;
            holder.classList.remove("clicked");
            console.log("close");
        });
    }
    // select.addEventListener("change", func)
    return {
        topImg: topImgElem,
        topText: nameElem
    };
}

function updateTrimLink() {
    const params = new URLSearchParams();
    params.append("helmet", helmetObj.getSubUrl());
    params.append("chestplate", chestplateObj.getSubUrl());
    params.append("leggings", leggingsObj.getSubUrl());
    params.append("boots", bootsObj.getSubUrl());
    const url = window.location.href + "?" + params;
    document.getElementById("link-to-trim").value = url;
}

function copyLink(e) {
    var copyText = e;
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
}

document.getElementById("link-to-trim").addEventListener("click", () => {
    copyLink(document.getElementById("link-to-trim"));
});

const canvasExtrasHolder = createElem("div", "", canvasHolder, "canvas-extras", []);

const gotoCopyLinkBtn = createElem("button", "share trim", canvasExtrasHolder, "share-btn", ["pop-out-box"]);
const downloadBtn = createElem("button", "screenshot model", canvasExtrasHolder, "", ["pop-out-box"]);

/* <p style="display: inline-block; margin: 0px 0px;">Background</p> */
// createElem("p", "background",canvasExtrasHolder, "", []);
canvasExtrasHolder.appendChild(backColorPicker);

downloadBtn.addEventListener("click", canvasToImg);

gotoCopyLinkBtn.addEventListener("click", () => {
    if (window.innerWidth > 700) document.getElementById("share-btn").scrollIntoView();
    else document.getElementById("more-tools").scrollIntoView();
    copyLink(document.getElementById("link-to-trim"));
});

function mathcListWStrStart(list, sub) {
    for (const item of list) {
        if (item.substring(0, 2) == sub) return item;
    }
}

// -------------------------------------------------------------------------------------------------------------

const trimSetsHolder = document.getElementById("trim-sets");

class TrimSetsHandler {

    constructor() {

        this.trimSets = [];
        this.currSetIndex = 0;
        this.lim = 100;
        this.hasUrlSet = false;
        this.namingIndex = 0;

        this.clickFunc = () => {

            // console.log("asd", this);

            if (this.trimSets.length >= this.lim)
                return;

            this.addTrim(this.getSetFrom3DModel())

            this.setCurrTrim(this.trimSets.length - 1);
        }

        // this.addElemSet = JSON.parse(JSON.stringify(trimSetsHolder.children[0]));
        this.newAddBtn();
        this.loadSetsOnStart();

        this.setCurrTrim(!this.hasUrlSet ? 0 : this.trimSets.length - 1);
    }

    addTrim(trimSet) {

        this.trimSets.push(trimSet);
        this.setuploadTrimSetBtn(trimSet);
        this.saveTrimToCookies(this.trimSets.indexOf(trimSet), trimSet)
    }

    loadSetsOnStart() {

        const setsToAdd = [];

        for (let i = 0; i < this.lim; i++) {

            const currSet = this.getSetFromCookie(i)

            if (currSet.helmet.material == "" || currSet.helmet.material == "undefined")
                break;

            setsToAdd.push(currSet)
        }

        const urlSet = this.getSetFromUrl();

        if (urlSet != undefined)
            setsToAdd.push(urlSet);

        if (setsToAdd.length == 0)
            setsToAdd.push(this.getSetFromRandom());

        let i = 0
        for (const currSet of setsToAdd) {

            // currSet.index = i;
            this.addTrim(currSet);
            i++;
        }
    }

    getSetFrom3DModel() {

        const i = this.trimSets.length;

        const newSet = {
            name: "Set " + this.getNameIndex(),
            //  index: this.trimSets.length
        };

        let pieceTypes = ["helmet", "chestplate", "leggings", "boots"]
        let pieceObjects = [helmetObj, chestplateObj, leggingsObj, bootsObj]

        let index = 0;

        for (const pieceType of pieceTypes) {

            newSet[pieceType] = {};
            newSet[pieceType].material = pieceObjects[index].armorType
            newSet[pieceType].pattern = pieceObjects[index].trimModel
            newSet[pieceType].palette = pieceObjects[index].trimPalette

            index++;
        }

        return newSet;
    }

    getNameIndex() {

        this.namingIndex++;
        return this.namingIndex;
    }

    getSetFromUrl() {

        const URLData = new URLSearchParams(window.location.search);

        if (URLData.get("helmet") == null)
            return undefined;

        this.hasUrlSet = true;
        this.lim++;
        const setFromUrl = { name: "url set" };

        for (const armorPieceType of armor_pieces) {

            const urlPiece = URLData.get(armorPieceType);

            if (URLData != null) {
                this.hasUrlSet = true;
                // console.log("aallah");
                const parts = urlPiece.split("_");

                setFromUrl[armorPieceType] =
                {
                    material: mathcListWStrStart(armor_types, parts[0]),
                    pattern: mathcListWStrStart(trimModels, parts[1]),
                    palette: mathcListWStrStart(trimPalettes, parts[2]),
                };
            }
        }
        history.replaceState(null, null, new URL(window.location.origin).href);

        return setFromUrl;
    }

    getSetFromCookie(i) {

        // console.log(i);
        const loadedSet = {};
        loadedSet.name = "Set " + this.getNameIndex();

        for (const armorPieceType of armor_pieces) {

            loadedSet[armorPieceType] = {};
            loadedSet[armorPieceType].material = getCookie(armorPieceType + "_material" + i);
            loadedSet[armorPieceType].pattern = getCookie(armorPieceType + "_pattern" + i);
            loadedSet[armorPieceType].palette = getCookie(armorPieceType + "_palette" + i);
        }

        return loadedSet;
    }

    getSetFromRandom() {

        const loadedSet = {};
        loadedSet.name = "Set " + this.getNameIndex();

        for (const armorPieceType of armor_pieces) {

            loadedSet[armorPieceType] = {};
            loadedSet[armorPieceType].material = armor_types[Math.floor(Math.random() * (armor_types.length - 0.01))];
            loadedSet[armorPieceType].pattern = trimModels[Math.floor(Math.random() * (trimModels.length - 0.01))];
            loadedSet[armorPieceType].palette = trimPalettes[Math.floor(Math.random() * (trimPalettes.length - 0.01))];
        }

        return loadedSet;
    }

    setuploadTrimSetBtn(trimSetToDisp) {

        // var index;

        let set = this.addElemSet;

        this.addElemSet.innerHTML = trimSetToDisp.name;
        this.addElemSet.removeEventListener("click", this.clickFunc)
        this.addElemSet.addEventListener("click", () => {

            // console.log(this.trimSets);
            // console.log(this.trimSets.indexOf(trimSetToDisp), trimSetToDisp);

            // index = this.trimSets.indexOf(trimSetToDisp)
            let index = [...trimSetsHolder.children].indexOf(set);
            this.setCurrTrim(index)
        })
        this.newAddBtn();
    }

    deleteCurrTrim() {

        this.deleteTrim(this.currSetIndex);
    }

    deleteTrim(removeIndex) {

        if (this.trimSets.length == 1)
            return;

        for (let i = removeIndex; i < this.trimSets.length; i++) {

            const trimSet = i == this.trimSets.length - 1 ? undefined : this.trimSets[i + 1];

            for (const pieceType of armor_pieces) {

                if (i == this.trimSets.length - 1) {

                    removeCookie(pieceType + "_material" + i);
                    removeCookie(pieceType + "_pattern" + i);
                    removeCookie(pieceType + "_palette" + i);
                    break;
                }

                addCookie(pieceType + "_material" + i, trimSet[pieceType].material);
                addCookie(pieceType + "_pattern" + i, trimSet[pieceType].pattern);
                addCookie(pieceType + "_palette" + i, trimSet[pieceType].palette);
            }
        }

        this.trimSets.splice(removeIndex, 1);
        trimSetsHolder.removeChild(trimSetsHolder.children[trimSetsHolder.children.length - 2]);
        this.setCurrTrim(this.currSetIndex == this.trimSets.length ? this.trimSets.length - 1 : this.currSetIndex);

        let i = 0
        for (const trimSet of this.trimSets) {

            trimSetsHolder.children[i].innerText = trimSet.name;

            i++;
        }
    }

    updateTrim(i, piece, material, pattern, palette) {

        this.trimSets[i][piece].material = material;
        this.trimSets[i][piece].pattern = pattern;
        this.trimSets[i][piece].palette = palette;

        addCookie(piece + "_material" + i, material);
        addCookie(piece + "_pattern" + i, pattern);
        addCookie(piece + "_palette" + i, palette);
    }

    updateCurrTrim(piece, material, pattern, palette) {

        this.updateTrim(this.currSetIndex, piece, material, pattern, palette)
    }

    setCurrTrim(index) {

        this.currSetIndex = index;

        for (const child of trimSetsHolder.children)
            child.classList.remove("selected-trim");

        trimSetsHolder.children[this.currSetIndex].classList.add("selected-trim");

        const currSet = this.trimSets[this.currSetIndex];
        // console.log(currSet);

        helmetObj.armorType = currSet.helmet.material;
        helmetObj.trimModel = currSet.helmet.pattern;
        helmetObj.trimPalette = currSet.helmet.palette;

        chestplateObj.armorType = currSet.chestplate.material;
        chestplateObj.trimModel = currSet.chestplate.pattern;
        chestplateObj.trimPalette = currSet.chestplate.palette;

        leggingsObj.armorType = currSet.leggings.material;
        leggingsObj.trimModel = currSet.leggings.pattern;
        leggingsObj.trimPalette = currSet.leggings.palette;

        bootsObj.armorType = currSet.boots.material;
        bootsObj.trimModel = currSet.boots.pattern;
        bootsObj.trimPalette = currSet.boots.palette;

        helmetObj.updateTexture();
        chestplateObj.updateTexture();
        leggingsObj.updateTexture();
        bootsObj.updateTexture();

        deleteBtn.style.top = ((54 + 20 + 6 + 10) * (index + 1) + 134) + "px";
    }

    saveTrimToCookies(i, trimSet) {

        let pieceTypes = ["helmet", "chestplate", "leggings", "boots"]

        for (const pieceType of pieceTypes) {
            addCookie(pieceType + "_material" + i, trimSet[pieceType].material);
            addCookie(pieceType + "_pattern" + i, trimSet[pieceType].pattern);
            addCookie(pieceType + "_palette" + i, trimSet[pieceType].palette);
        }
    }

    newAddBtn() {

        if (this.trimSets.length >= this.lim)
            return;

        this.addElemSet = document.createElement("div")
        const circle = createElem("div", "", this.addElemSet, "", []);
        createElem("div", "", circle, "", []);
        createElem("div", "", circle, "", []);

        this.addElemSet.addEventListener("click", this.clickFunc);

        trimSetsHolder.appendChild(this.addElemSet);
    }
}
trimSetsHandler = new TrimSetsHandler();

deleteBtn.addEventListener("click", () => {

    trimSetsHandler.deleteCurrTrim();
});

function canvasToImg() {

    const dataURL = document.getElementsByTagName("canvas")[0].toDataURL("image/png");

    const a = document.createElement('a');
    a.href = dataURL
    a.download = `${"armor_trim"}.jpeg`;
    // a.download = `${"armor_trim"}.png`;
    a.click();
}

// canvasToImg();

// -----------------------------------------------

const tabs = {

    "model": document.getElementById("canvas-holder"),
    "trims": document.getElementById("armor-values")
};

function openTab(tabKey) {

    hideAllTabs();
    tabs[tabKey].classList.remove("hide-block");
}

function hideAllTabs() {

    for (const tab in tabs) {
        if (Object.hasOwnProperty.call(tabs, tab)) {

            tabs[tab].classList.add("hide-block");
        }
    }
}

document.getElementById("open-model").addEventListener("click", () => openTab("model"));
document.getElementById("open-trims").addEventListener("click", () => openTab("trims"));

// -------------------------------------------------------------------------

function addCalcTrimFuncs() {
    const calcFindInputs = document.getElementsByClassName("calc-find-chance");

    for (const findInp of calcFindInputs) {
        addCalcTrimFunc(findInp);
    }
}

function addCalcTrimFunc(elem) {

    // console.log(elem);

    elem.addEventListener("input", () => {

        console.log("asd");
        const parsedChance = parseFloat(elem.getAttribute("chance"))
        const chanceToNotFind = 1 - parsedChance;
        const checks = parseInt(elem.value);

        let chanceToFind = 1 - (chanceToNotFind ** checks);
        const decimals = 2;
        // chanceToFind = Math.floor(chanceToFind * 10 ** (decimals + 2)) / (10 ** decimals)
        chanceToFind = toPercent(chanceToFind, 2)

        if (checks == 1)
            chanceToFind = toPercent(parsedChance, 1);

        elem.parentElement.lastElementChild.innerText = "chance to find: " + chanceToFind + "%";
    })
}

function toPercent(number, decimals) {

    return Math.floor(number * 10 ** (decimals + 2)) / (10 ** decimals)
}

addCalcTrimFuncs();

function updateSelectedTrimsDisp() {

    const objects = [
        helmetObj,
        chestplateObj,
        leggingsObj,
        bootsObj
    ];

    selectedTrimsDisp.innerHTML = "<h2>Selected trims</h2><p></p>";

    for (const obj of objects) {

        for (const trimInfo of document.getElementById("all-armor-trims").children) {

            if (trimInfo.getAttribute("name") == obj.trimModel) {
                const newElem = `<div>${trimInfo.innerHTML}</>`;
                selectedTrimsDisp.innerHTML += newElem;
                // console.log(selectedTrimsDisp.lastElementChild.getElementsByTagName("input")[0]);
                // addCalcTrimFunc(selectedTrimsDisp.lastElementChild.getElementsByTagName("input")[0]);

                // newTrimInfoBlocks.push(selectedTrimsDisp.lastElementChild);
                break;
            }
        }
    }

    for (const trimBlock of selectedTrimsDisp.children) {

        if (trimBlock.tagName != "DIV")
            continue;

        // console.log(trimBlock);
        addCalcTrimFunc(trimBlock.getElementsByTagName("input")[0]);
    }
}

// ----------------------------------------------

const skinSettings = document.getElementById("skin-settings");
const container = skinSettings.parentElement;

// Move the element to the end of the container
container.appendChild(skinSettings);